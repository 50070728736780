.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.flex{
  display: flex;
}
.center-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-profile .fileContainer{
  box-shadow: none;
  padding: 0px;
  margin: 0px;
}

.edit-profile{
  display: flex;
  align-items: center;
}

.edit-profile .fileContainer input{
  width: 150px;
}

.edit-profile .fileContainer .chooseFileButton{
  background: #0089ff;
  border-radius: 6px;
  color: white;
  font-weight: 400;
  font-size: 16px;
  height: 40px;
}

.loading-avatar{
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-flex-wrap{
  display: flex!important;
  flex-wrap: wrap;
}

.img-container{
  position: relative;
  width: 90px;
  height: 120px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.image {
  width: 90px;
  height: 120px;
}
.img-overlay{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .3s ease;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.img-overlay-status{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: start;
  text-shadow: 0.5px 0.5px #ffffff;
}

.img-container:hover .img-overlay {
  opacity: 0.8;
}
.img-pointer{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.uploadBtn {
  border: 2px solid rgb(67, 137, 241);
  color: white;
  background-color: rgb(67, 137, 241);
  padding: 5px 15px;
  border-radius: 8px;
  font-size: 16px;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
